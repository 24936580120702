import { FC, useRef, useState } from 'react';
import { VariantImage } from 'shared/types/product/Variant';
import Swiper from 'swiper';
import Slider from 'components/commercetools-ui/atoms/slider';
import CloseIcon from 'components/icons/CloseIcon';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { md } from 'helpers/utils/screensizes';
import Image from 'frontastic/lib/image';

interface LightboxGalleryProps {
  images: VariantImage[];
  lightbox: { closeLightbox: () => void };
}

const LightboxGallery: FC<LightboxGalleryProps> = ({ images, lightbox }) => {
  const swiperRef = useRef<Swiper>();
  const [activeSlide, setActiveSlide] = useState(0);

  const [isAboveMd] = useMediaQuery(md);

  const slideTo = (slide: number) => {
    swiperRef.current?.slideTo(slide);
  };

  const handleSlide = (swiper: Swiper) => {
    setActiveSlide(swiper.realIndex);
  };

  const thumbnails = images?.map((image, index) => (
    <div
      key={index}
      className={`h-70 w-70 border p-7 ${
        index == activeSlide % images.length ? 'border-base-accent-1' : 'border-base-accent-2'
      }`}
    >
      <div className="h-full w-full" onClick={() => slideTo(index)}>
        <Image
          cloudimage={true}
          src={image.src}
          alt={`${image.alt} - zwięzły${index === 0 ? '' : ` nr ${index}`}`}
          className="h-full w-full p-7 hover:cursor-pointer"
        />
      </div>
    </div>
  ));

  const sliderAndImageContainerClassNames =
    'mx-auto h-[calc(100dvh-96px)] !w-[calc(100%-24px)] md:h-[500px] md:!w-[calc(100%-180px)]';

  return (
    <div className="flex flex-col">
      <div className="flex justify-end pr-12 pt-12 md:pr-24 md:pt-20">
        <button onClick={() => lightbox.closeLightbox()}>{<CloseIcon />}</button>
      </div>

      {/* Main image */}
      <div>
        <Slider
          variation="gallery"
          onSlideChange={handleSlide}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          arrows
          dots={false}
          prevButtonStyles={{ left: 32 }}
          nextButtonStyles={{ right: 32 }}
          prevArrowIconProps={{ scale: 1.3 }}
          nextArrowIconProps={{ scale: 1.3 }}
          compactNavigation={!isAboveMd}
          slidesPerView={1}
          enableZoom={true}
          className={sliderAndImageContainerClassNames}
        >
          {images?.map((image, index) => (
            <div className={sliderAndImageContainerClassNames} key={index}>
              <Image
                cloudimage={true}
                src={image.src}
                alt={`${image.alt}${index === 0 ? '' : ` - zdjęcie nr ${index}`}`}
                loading={index === 0 ? 'eager' : undefined}
                zoomContainer={true}
              />
            </div>
          ))}
        </Slider>
      </div>

      {/* Thumbnails */}
      <div className="hidden justify-center gap-x-18 py-12 md:flex">
        {images.length > 5 ? (
          <Slider
            swiperBackgroundColor="transparent"
            dots={false}
            arrows
            slidesPerView={5}
            slideWidth={70}
            fitToSlides
            prevArrowStyles={{ padding: '4px' }}
            nextArrowStyles={{ padding: '4px' }}
          >
            {thumbnails}
          </Slider>
        ) : (
          thumbnails
        )}
      </div>
    </div>
  );
};

export default LightboxGallery;
