import { FC, useEffect, useMemo, useState } from 'react';
import { Money } from 'shared/types/product';
import { LineItem } from 'shared/types/wishlist/LineItem';
import CartButton from 'components/commercetools-ui/atoms/cart-button';
import useTrack from 'components/commercetools-ui/atoms/cart-button/useTrack';
import triggerToast from 'components/commercetools-ui/atoms/toaster/utils/triggerToast';
import Typography from 'components/commercetools-ui/atoms/typography';
import WishlistButton from 'components/commercetools-ui/organisms/wishlist/components/wishlist-button';
import TextLabel from 'components/icons/labels/TextLabel';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import { useCart } from 'frontastic';
import ContactInfoSection from './contact-info-section';
import LabelsSection from './labels-section';
import PromotionalInfo from '../../product-tile/components/promotional-info';
import Prices from '../../product-tile/prices';
import { UIProduct } from '../types';

export type PriceBruttoVat = {
  vatPrice: Money;
  vatText: string;
};
export type PriceBrutto = {
  price: Money;
  vatInfo: PriceBruttoVat;
};

export type ProductSummaryLabelType = 'promotion' | 'freeShipping' | 'delivery' | 'madeInPoland';

export type ProductSummaryLabels = {
  labelType: ProductSummaryLabelType;
  labelValue?: string;
};

export type ProductSummaryPromotionalInfo = {
  promotionalInfoText: string;
  amount?: Money;
};
export interface ProductSummaryProps {
  product: UIProduct;
  disabled?: boolean;
}

const ProductSummary: FC<ProductSummaryProps> = ({ product, disabled = false }) => {
  const variant = product?.variants?.[0];

  const [isOverTabletSize] = useMediaQuery(tablet);
  const { data, addItem, removeItem, updateItem } = useCart();
  const [quantity, setQuantity] = useState<number>(0);
  const [inputQuantity, setInputQuantity] = useState<number>(0);
  const [added, setAdded] = useState(false);
  const [removed, setRemoved] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);

  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

  const { trackAddToCartProduct, trackAddToCartLineItem } = useTrack();

  const lineItemsOverLimit_genericError = formatCartMessage({
    id: 'lineItemsOverLimit.error.generic',
    defaultMessage: 'The maximum number of products in the order has been reached.',
  });
  const lineItemsOverLimit_individualErrorPart1 = formatCartMessage({
    id: 'lineItemsOverLimit.error.individual.part1',
    defaultMessage: 'Maximum quantity for product',
  });
  const lineItemsOverLimit_individualErrorPart2 = formatCartMessage({
    id: 'lineItemsOverLimit.error.individual.part2',
    defaultMessage: 'in the order was reached.',
  });

  useEffect(() => {
    if (data?.validations?.lineItemsOverTheOrderLimit?.isValid == false) {
      if (data?.validations?.lineItemsOverTheOrderLimit?.validationType == 'generic') {
        triggerToast({ message: lineItemsOverLimit_genericError }, { id: '1' });
      }

      if (data?.validations?.lineItemsOverTheOrderLimit?.validationType == 'individual') {
        data?.validations?.lineItemsOverTheOrderLimit.lineItems.forEach((sku) => {
          triggerToast(
            {
              message: `${lineItemsOverLimit_individualErrorPart1} ${
                data?.lineItems?.find((lineItem) => lineItem.variant?.sku === sku)?.name
              } ${lineItemsOverLimit_individualErrorPart2}`,
            },
            { id: sku },
          );
        });
      }
    }

    if (data?.lineItems) {
      const lineItem = data?.lineItems?.find((lineItem) => lineItem.variant?.sku === variant?.sku);
      if (lineItem?.count) {
        setQuantity(lineItem.count);
      } else {
        setQuantity(0);
      }
    }
  }, [
    data?.lineItems,
    variant?.sku,
    data,
    quantity,
    lineItemsOverLimit_genericError,
    lineItemsOverLimit_individualErrorPart1,
    lineItemsOverLimit_individualErrorPart2,
  ]);

  const productSummaryLabels = useMemo<ProductSummaryLabels[]>(() => {
    const labels: ProductSummaryLabels[] = [];
    if (variant?.attributes?.madeInPoland?.value) {
      labels.push({ labelType: 'madeInPoland', labelValue: variant.attributes.madeInPoland.localizedLabel });
    }
    if (variant?.isOnStock) {
      labels.push({
        labelType: 'delivery',
        labelValue: formatProductMessage({ id: 'delivery', defaultMessage: 'Delivery within 1-3 days' }),
      });
    }
    return labels;
  }, [variant, formatProductMessage]);

  const productToWishlistLineItem = useMemo<LineItem | undefined>(() => {
    if (product) {
      return {
        lineItemId: product.productId ?? '',
        productId: product.productId,
        name: product.name,
        count: 1,
        variant,
        addedAt: new Date(),
        _url: product._url,
      };
    }
  }, [product, variant]);

  const handleAddToCart = async () => {
    setLoadingAdd(true);

    trackAddToCartProduct(product, quantity + 1);

    addItem(variant, 1).then(() => {
      setLoadingAdd(false);

      setAdded(true);
      setTimeout(() => {
        setAdded(false);
        setQuantity(quantity + 1);
      }, 1000);
    });
  };

  // handleUpdateCartItem is used for both updating a value as result of a change in the input field and for removing an item from the cart completely
  const handleUpdateCartItem = async (newQuantity: number) => {
    if (loadingRemove) return;
    newQuantity > quantity ? setLoadingAdd(true) : setLoadingRemove(true);

    const lineItem = data?.lineItems?.find((lineItem) => lineItem.variant?.sku === variant?.sku);
    if (!lineItem?.lineItemId) return;

    if (newQuantity < 1) {
      await removeItem(lineItem.lineItemId as string);
    } else {
      trackAddToCartLineItem(lineItem, newQuantity);
      await updateItem(lineItem.lineItemId as string, newQuantity);
    }
    if (newQuantity > quantity) {
      setLoadingAdd(false);
      setAdded(true);
    } else {
      setLoadingRemove(false);
      setRemoved(true);
    }
    setTimeout(() => {
      setAdded(false);
      setRemoved(false);
      setQuantity(newQuantity);
    }, 1500);
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = +(e.target as HTMLInputElement).value;
    setInputQuantity(newQuantity);
  };

  const handleQuantityBlur = (e: React.FormEvent) => {
    const newQuantity = +(e.target as HTMLSelectElement).value;
    if (quantity === newQuantity) {
      return;
    }

    handleUpdateCartItem(newQuantity);
    setInputQuantity(0);
    quantity > newQuantity ? setLoadingRemove(true) : setLoadingAdd(true);

    const loadingTimer = setTimeout(() => {
      if (quantity > newQuantity) {
        setLoadingRemove(false);
        setRemoved(true);
      } else {
        setLoadingAdd(false);
        setAdded(true);
      }
      clearTimeout(loadingTimer);

      const addedTimer = setTimeout(() => {
        quantity > newQuantity ? setRemoved(false) : setAdded(false);
        clearTimeout(addedTimer);
      }, 500);
    }, 1500);
  };

  return (
    <div className="flex w-full flex-col items-start justify-center gap-16 bg-neutral-5 py-24 md:px-20 md:py-32 md:shadow-[0_1px_5px_0] md:shadow-neutral-2">
      {/* Product name */}
      <div className="flex flex-wrap">
        <Typography className="text-20 font-bold leading-[25px]" as="h1">
          {product.name}
        </Typography>
      </div>

      <div className="flex flex-col">
        {/* Product promotion label */}
        {(variant.strikeThroughPrice || variant.percentOfDiscount) && !disabled ? (
          <div className="mb-12 flex">
            <TextLabel variant="promotion" />
          </div>
        ) : null}

        {/* Product price */}
        <Prices
          price={variant?.price}
          percentOfDiscount={variant?.percentOfDiscount}
          strikeThroughPrice={variant?.strikeThroughPrice}
          bestPrice30={variant?.bestPrice30}
          variant="product-summary"
          vatText={`${variant?.attributes?.packageSize?.value} ${formatProductMessage({
            id: 'baseUnit',
            defaultMessage: 'szt.',
          })} ${formatProductMessage({
            id: 'including',
            defaultMessage: 'incl.',
          })} ${formatProductMessage({ id: 'vat', defaultMessage: 'VAT' })}`}
          additionalClassName="!mt-0"
          disabled={disabled}
        />

        {/* Product price netto */}
        {variant.netPrice ? (
          <div className="mt-8 flex flex-col gap-10">
            <p className={`text-14 leading-[17.5px] ${disabled ? 'text-neutral-2' : 'text-neutral-3'}`}>
              <span>{CurrencyHelpers.formatForCurrency(variant.netPrice)}</span>{' '}
              {formatProductMessage({ id: 'netto', defaultMessage: 'netto' })}
            </p>
          </div>
        ) : null}

        {/* Historical price */}
        <PromotionalInfo
          additionalClassName={`${disabled ? '!text-neutral-2' : ''} mt-8`}
          strikeThroughPrice={variant.strikeThroughPrice}
          bestPrice30={variant.bestPrice30}
        />

        {/* Product price brutto */}
        {variant.basePrice ? (
          <div className="mt-8 flex flex-col gap-y-10">
            <p className={`text-14 leading-[17.5px] ${disabled ? 'text-neutral-2' : 'text-neutral-3'}`}>
              <span>{CurrencyHelpers.formatForCurrency(variant.basePrice)}</span>{' '}
              {formatProductMessage({ id: 'basePriceText', defaultMessage: 'gross per kilo' })}
            </p>
          </div>
        ) : null}
      </div>

      {/* Add to cart and Wishlist buttons */}
      <div className="flex items-center justify-between self-stretch">
        {isOverTabletSize ? (
          <>
            {disabled ? (
              <Typography className="text-14 leading-[17.5px] text-base-brand-1">
                {formatProductMessage({ id: 'noStock', defaultMessage: 'This Product is not in stock' })}
              </Typography>
            ) : (
              <CartButton
                addToCart={handleAddToCart}
                removeFromCart={() => handleUpdateCartItem(quantity - 1)}
                onChange={handleQuantityChange}
                onBlur={handleQuantityBlur}
                loadingAdd={loadingAdd}
                loadingRemove={loadingRemove}
                added={added}
                removed={removed}
                quantity={quantity}
                inputQuantity={inputQuantity}
              />
            )}
            <div className="flex items-end">
              {productToWishlistLineItem ? (
                <WishlistButton lineItem={productToWishlistLineItem} className="h-48 w-48" />
              ) : null}
            </div>
          </>
        ) : (
          <>
            {productToWishlistLineItem ? (
              <WishlistButton lineItem={productToWishlistLineItem} className="h-48 w-48" />
            ) : null}
            {disabled ? (
              <Typography className="text-14 leading-[17.5px] text-base-brand-1">
                {formatProductMessage({ id: 'noStock', defaultMessage: 'This Product is not in stock' })}
              </Typography>
            ) : (
              <div className="flex items-end">
                <CartButton
                  addToCart={handleAddToCart}
                  removeFromCart={() => handleUpdateCartItem(quantity - 1)}
                  onChange={handleQuantityChange}
                  onBlur={handleQuantityBlur}
                  loadingAdd={loadingAdd}
                  loadingRemove={loadingRemove}
                  added={added}
                  removed={removed}
                  quantity={quantity}
                  inputQuantity={inputQuantity}
                />
              </div>
            )}
          </>
        )}
      </div>

      {/* Contact info */}
      {!disabled ? <ContactInfoSection /> : null}

      {/* Labels */}
      {productSummaryLabels ? <LabelsSection labels={productSummaryLabels} disabled={disabled} /> : null}
    </div>
  );
};

export default ProductSummary;
