import { Attributes } from 'shared/types/product/Attributes';

export type Attribute = {
  sortId?: string;
  value: any;
  localizedLabel?: string;
};

export type ProductParameter = {
  key: string;
  value: string;
};

const getAttribute = (attribute: Attribute) => {
  const value = Object.fromEntries(
    attribute.value.value.map((entry: any) => {
      const entryValues = entry.split(':');
      const entryKey = entryValues[0];
      const entryValue = entryValues.slice(1).join(':');

      return [entryKey, entryValue];
    }),
  );

  return {
    localizedLabel: attribute.value.localizedLabel,
    sortId: attribute.value.sortId,
    value: value,
  };
};

const sortAttributes = (attributes?: Attributes) => {
  if (!attributes) return {};

  const attributesArray = Object.entries(attributes).map(([key, value]) => ({
    key,
    value,
  }));

  // Separated attributes that has as value a list in attributes category
  // Category attributes separated in productParameters
  // The rest of attributes will be saved in additionalInformation
  const productParameters: ProductParameter[] = [];
  const additionalInformation: Attribute[] = [];
  const attributesCategory: Attribute[] = [];

  const exceptionListAttributesWithoutSortId = ['producer', 'returnInformation'];

  let producer;
  let returnInformation;

  attributesArray
    .filter((attribute) => attribute.value.sortId || exceptionListAttributesWithoutSortId.includes(attribute.key))
    .sort((a, b) => Number(a.value.sortId) - Number(b.value.sortId))
    .forEach((attribute) => {
      if (attribute.key === 'category') {
        attribute.value.value.forEach((category: any) => {
          const searchParameter = category.split(':');
          productParameters.push({
            key: searchParameter[0],
            value: searchParameter[1],
          });
        });
      } else if (Array.isArray(attribute?.value.value) && attribute.value.value.length > 1) {
        if (attribute.key === 'producer') {
          producer = getAttribute(attribute);
        } else if (attribute.key === 'returnInformation') {
          returnInformation = getAttribute(attribute);
        } else {
          attributesCategory.push(attribute.value);
        }
      } else {
        additionalInformation.push(attribute.value);
      }
    });

  producer && additionalInformation.push(producer);
  returnInformation && additionalInformation.push(returnInformation);

  return {
    additionalInformation,
    productParameters,
    attributesCategory,
  };
};

export default sortAttributes;
