import React, { forwardRef } from 'react';
import { VariantImage } from 'shared/types/product/Variant';
import LightboxGallery from './lightbox-gallery';

type LightboxProps = {
  images: VariantImage[];
  closeLightbox: () => void;
};

const Lightbox = forwardRef<HTMLDialogElement, LightboxProps>(({ images, closeLightbox }, ref) => {
  const baseStyles = 'backdrop:bg-secondary-black backdrop:opacity-30';
  const mobileStyles = 'max-md:!h-[100dvh] max-md:!max-h-[100dvh] max-md:!w-screen max-md:!max-w-screen-md';
  const desktopStyles = 'lg:w-[min(90vw,1440px)]';

  return (
    <dialog
      ref={ref}
      className={`${baseStyles} ${mobileStyles} ${desktopStyles}`}
      onClick={(e) => {
        if (e.target === e.currentTarget) closeLightbox();
      }}
    >
      <LightboxGallery images={images} lightbox={{ closeLightbox }} />
    </dialog>
  );
});

Lightbox.displayName = 'Lightbox';

export default Lightbox;
