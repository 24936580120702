import { FC, useRef, useState } from 'react';
import { VariantImage } from 'shared/types/product/Variant';
import Swiper from 'swiper';
import Slider from 'components/commercetools-ui/atoms/slider';
import useClassNames from 'helpers/hooks/useClassNames';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import Image from 'frontastic/lib/image';
import { ProductDetailsProps } from '../product/product-details';

interface GalleryProps {
  images: VariantImage[];
  inModalVersion?: ProductDetailsProps['inModalVersion'];
  openLightbox?: () => void;
}

const Gallery: FC<GalleryProps> = ({ images, inModalVersion, openLightbox }) => {
  const swiperRef = useRef<Swiper>();
  const [activeSlide, setActiveSlide] = useState(0);

  const [isTabletSize] = useMediaQuery(tablet);

  const slideTo = (slide: number) => {
    swiperRef.current?.slideTo(slide);
  };

  const handleSlide = (swiper: Swiper) => {
    setActiveSlide(swiper.realIndex);
  };

  const imagesContainerClassName = useClassNames([
    'relative mx-20 lg:mx-auto [&>div>img]:!p-32 [&>div>img]:bg-neutral-5 hover:cursor-pointer',
    inModalVersion ? 'h-[250px] max-w-[300px]' : 'h-[264px] md:h-[394px]',
  ]);

  const thumbnails = images?.map((image, index) => (
    <div
      key={index}
      className={`h-70 w-70 border p-7 ${
        index == activeSlide % images.length ? 'border-base-accent-1' : 'border-base-accent-2'
      }`}
    >
      <div className="relative h-full w-full" onClick={() => slideTo(index)}>
        <Image
          cloudimage={true}
          src={image.src}
          alt={`${image.alt} - zwięzły${index === 0 ? '' : ` nr ${index}`}`}
          className="h-full w-full p-7 hover:cursor-pointer"
        />
      </div>
    </div>
  ));

  const handleMainImageClick = () => {
    if (openLightbox) openLightbox();
  };

  return (
    <div className="gap-y-34 px-4 md:mb-50">
      {/* Main image */}
      <div className="min-h-[304px]">
        <Slider
          variation="gallery"
          onSlideChange={handleSlide}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          arrows
          dots={false}
          prevButtonStyles={{ left: 32 }}
          nextButtonStyles={{ right: 32 }}
          prevArrowIconProps={{ scale: 1.6 }}
          nextArrowIconProps={{ scale: 1.6 }}
          compactNavigation={!isTabletSize}
          slidesPerView={1}
        >
          {images?.map((image, index) => (
            <div className={imagesContainerClassName} key={index} onClick={handleMainImageClick}>
              <Image
                cloudimage={true}
                src={image.src}
                alt={`${image.alt}${index === 0 ? '' : ` - zdjęcie nr ${index}`}`}
                loading={index === 0 ? 'eager' : undefined}
              />
            </div>
          ))}
        </Slider>
      </div>

      {/* Thumbnails */}
      {!inModalVersion && (
        <div className="mt-16 hidden gap-x-18 md:flex">
          {images.length > 5 ? (
            <Slider
              containerClassName="ml-50 w-[430px]"
              swiperBackgroundColor="transparent"
              dots={false}
              arrows
              slidesPerView={5}
              slideWidth={70}
              fitToSlides
              prevArrowStyles={{ padding: '4px' }}
              nextArrowStyles={{ padding: '4px' }}
            >
              {thumbnails}
            </Slider>
          ) : (
            thumbnails
          )}
        </div>
      )}
    </div>
  );
};

export default Gallery;
