import React, { FC, useEffect, useRef, useState } from 'react';
import { Variant } from 'shared/types/product';
import { Category } from 'shared/types/product/Category';
import Container from 'components/commercetools-ui/atoms/container';
import Gallery from 'components/commercetools-ui/organisms/gallery';
import useClassNames from 'helpers/hooks/useClassNames';
import { useProduct } from 'frontastic';
import AdditionalInfo from './components/additional-info';
import Breadcrumbs from './components/product-breadcrumb';
import ProductSummary from './components/product-summary';
import { UIProduct } from './types';
import Lightbox from '../../gallery/lightbox';

export interface ProductDetailsProps {
  product: UIProduct;
  variant: Variant;
  url?: string;
  onChangeVariant: (sku: string) => void;
  inModalVersion?: boolean;
  setIsOpen?: (value: boolean) => void;
  onAddToCart?: () => void;
}

const ProductDetails: FC<ProductDetailsProps> = ({ product, variant, inModalVersion }) => {
  const [category, setCategory] = useState<Category>();

  const { categories } = useProduct();

  useEffect(() => {
    if (product && categories && !category) {
      const currentCategory = categories.find((cat) => cat.categoryId === product.categories?.[0].categoryId);
      setCategory(currentCategory);
    }
  }, [categories, category, product, product?.categories]);

  const isOnStock = product?.variants[0]?.isOnStock ?? false;

  const wrapperClassName = `bg-background max-sm:relative ${
    inModalVersion
      ? 'md:grid grid-cols-12 pt-70 pb-35 px-20 md:pr-36'
      : 'sm:pt-16 pb-32 md:grid md:grid-cols-12 md:items-start lg:px-48 md:px-24 px-16'
  }`;

  const galleryContainerClassName = useClassNames([
    inModalVersion ? 'col-span-6' : 'md:col-span-7 lg:col-span-8',
    'md:pr-8 max-sm:bg-neutral-5 max-sm:pt-16',
  ]);

  const informationContainerClassName = useClassNames([
    inModalVersion ? 'col-span-6' : 'md:col-span-5 lg:col-span-4',
    'mt-24 md:mt-0',
  ]);

  const ref = useRef<HTMLDialogElement>(null);
  const openLightbox = () => {
    if (ref.current) ref.current.showModal();
  };
  const closeLightbox = () => {
    if (ref.current) ref.current.close();
  };

  const images = variant?.images ?? [];

  return (
    <Container>
      <div className={wrapperClassName}>
        {categories && category && !inModalVersion && (
          <div className="w-fit max-sm:absolute max-sm:z-10 max-sm:pt-16 md:col-span-12 md:mb-16 [&>nav>ol]:px-0 sm:[&>nav>ol]:px-4">
            <Breadcrumbs categories={categories} categoryId={category?.categoryId} />
          </div>
        )}

        <div className={galleryContainerClassName}>
          <Gallery images={images} openLightbox={openLightbox} inModalVersion={inModalVersion} />
          <Lightbox images={images} closeLightbox={closeLightbox} ref={ref} />
        </div>

        <div className={informationContainerClassName}>
          {!product && <div className="h-[450px] bg-neutral-5 md:hidden" />}
          {product && <ProductSummary product={product} disabled={!isOnStock} />}
        </div>

        {!inModalVersion && product && (
          <div className="grid gap-y-34 max-sm:!bg-neutral-5 md:col-span-7 md:mb-50 md:pr-20 lg:col-span-8 lg:pr-52">
            <AdditionalInfo attributes={variant?.attributes} product={product} />
          </div>
        )}
      </div>
    </Container>
  );
};

export default ProductDetails;
